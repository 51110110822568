<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-10-22 16:27:04
 * @LastEditors: 郭长生
 * @LastEditTime: 2024-09-25 17:12:46
-->
<template>
    <div>
        <div class="topBox">
            <el-breadcrumb separator="/" class="breadcrumb">
                <el-breadcrumb-item>{{ $t('route.disinfect') }}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button @click="exportExcel" class="exportBtn" type="primary" @keyup.space.prevent.native @keydown.enter.prevent.native>{{ $t('disinfect.export') }}</el-button>
        </div>
        <div class="contentWrapper disinfectList">
            <el-form ref="searchForm" :model="formInline" class="demo-form-inline">
                <el-row>
                    <!-- <span> -->
                    <el-form-item :label="`${$t('route.robot')}：`" prop="sn" label-position="left">
                        <el-select v-model="formInline.sn" :placeholder="$t('system.choice')" clearable>
                            <el-option
                                v-for="item in robotList"
                                :key="item.ID"
                                :label="item.nickname ? (item.status == 'leave' ? item.nickname + `${$t('user.leave')}` : item.nickname) : item.status == 'leave' ? setRobotName(item.software_num) + `${$t('user.leave')}` : setRobotName(item.software_num)"
                                :value="item.sn"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- </span>
          <span> -->
                    <el-form-item :label="`${$t('user.arearoom')}：`" prop="area_id">
                        <DisinfectRoom :roomData="roomData" @getRoomData="getRoomData" clearable />
                    </el-form-item>
                    <!-- </span>
          <span> -->
                    <el-form-item :label="`${$t('disinfect.pattern')}：`" prop="disinfect_type">
                        <el-select v-model="formInline.disinfect_type" :placeholder="$t('system.choice')" clearable>
                            <el-option v-for="item in patternList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- </span>
          <span v-show="isShow"> -->
                    <el-form-item :label="`${$t('system.status')}：`" prop="status" v-show="isShow">
                        <el-select v-model="disinfectStatus" :placeholder="$t('system.choice')" clearable>
                            <el-option v-for="item in statusList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- </span>
          <span v-show="isShow"> -->

                    <el-form-item :label="`${$t('system.createTime')}：`" prop="create_time" v-show="isShow" class="createtimeClass">
                        <!-- <el-date-picker
                v-model="formInline.create_time"
                type="date"
                :format="momentStamp('date')"
                :placeholder="$t('system.choice')"
                :disabled-date="disabledDate"
                style="width: 84%"
                clearable 
              ></el-date-picker> -->
                        <el-date-picker v-model="create_time" type="daterange" :format="momentStamp('date')" range-separator="" :start-placeholder="`${$t('user.cong')}`" :end-placeholder="`${$t('user.dao')}`" :disabled-date="disabledDate" />
                    </el-form-item>
                    <!-- </span> -->
                    <el-form-item :label="`${$t('user.operator')}：`" prop="status" v-show="isShow">
                        <el-select v-model="formInline.operator" :placeholder="$t('system.choice')" clearable>
                            <el-option v-for="(item, key) in operatorList" :key="key" :value="item.username == '' ? item.email : item.username"></el-option>
                        </el-select>
                    </el-form-item>
                    <span :xs="12" :sm="6" :offset="isShow ? 12 : 0" class="searchBtn">
                        <el-button type="primary" @click="handleSearch" @keyup.space.prevent.native @keydown.enter.prevent.native>{{ $t('system.search') }}</el-button>
                        <el-button @click="resetForm('searchForm')" @keyup.space.prevent.native @keydown.enter.prevent.native style="margin-left: 24px">{{ $t('system.reset') }}</el-button>
                        <span @click="getOpen(true)" class="openText" v-show="!isShow">
                            {{ $t('system.open') }}
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </span>
                        <span @click="getOpen(false)" class="openText" v-show="isShow">
                            {{ $t('system.retract') }}
                            <i class="fa fa-angle-up" aria-hidden="true"></i>
                        </span>
                    </span>
                </el-row>
            </el-form>
            <el-table :data="tableData" @row-click="goTaskInfo" :row-class-name="tableRowClassName" style="width: 100%;">
                <el-table-column prop="" :label="$t('route.robot')">
                    <template #default="{row}">{{ row.robot_nickname ? (row.robot_status == 'leave' ? row.robot_nickname + `${$t('user.leave')}` : row.robot_nickname) : row.robot_status == 'leave' ? row.ssid + `${$t('user.leave')}` : row.ssid }}</template>
                </el-table-column>
                <el-table-column prop="room_name" :label="$t('disinfect.room')" />
                <el-table-column prop="disinfect_type" :label="$t('disinfect.pattern')">
                    <template #default="{row}">{{ disinfectType(row.disinfect_type) }}</template>
                </el-table-column>
                <el-table-column :label="$t('disinfect.totalTime')">
                    <template #default="{row}">{{ row.count_time == 0 ? '--' : getHM(row.count_time) }}</template>
                </el-table-column>
                <el-table-column :label="$t('disinfect.rwzsc')">
                    <template #default="{row}">{{ row.end_time - row.create_time <= 0 ? '--' : getHM(row.end_time - row.create_time) }}</template>
                </el-table-column>
                <el-table-column prop="status" :label="$t('system.status')">
                    <template #default="{row}">
                        <span class="circular" :style="{ background: filterStatus(row.status, 1) }"></span>
                        <span class="circular_t">{{ filterStatus(row.status, 2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="operator" :label="$t('user.operator')" />
                <el-table-column prop="create_time" :label="$t('system.createTime')">
                    <template #default="{row}">
                        <span>{{ moment(row.create_time * 1000).format(momentStamp('datetime')) }}</span>
                    </template>
                </el-table-column>

                <template #empty>
                    <div class="tableNodata">
                        <img :src="totalImg.nodata" />
                        <p>{{ $t('system.noTask') }}</p>
                    </div>
                </template>
            </el-table>
            <el-pagination
                background
                v-if="total / formInline.pageSize > 1"
                :page-size="formInline.pageSize"
                :total="total"
                :current-page="page"
                :page-sizes="[12, 24, 48, 96, 200]"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                layout="total, prev, pager, next, sizes, jumper"
                class="pagination"
            />
            <div v-else class="noPage">{{ $t('system.page').replace('XX', total) }}</div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, watch, ref, nextTick, toRefs, unref } from 'vue';
import { roomInfo } from '@/apis/region';
import { getRobotList } from '@/apis/user';
import { useRouter, useRoute } from 'vue-router';
import moment from 'moment';
import { ElForm } from 'element-plus';
import { getHM, totalImg, setRobotName, disinfectType, valueFilter, momentStamp } from '@/utils/robot';
import { taskList, exportTask } from '@/apis/robot';
import { getAllUser } from '@/apis/user';
import DisinfectRoom from '@/components/disinfect/DisinfectRoom';
import { ElMessageBox, ElMessage } from 'element-plus';
import { getLanguage } from '@/utils/cookies';
import i18n from '@/locales/index';
let _t = i18n.global.t;
export default {
    components: { DisinfectRoom },
    setup() {
        const searchForm = ref(ElForm);
        const router = useRouter();
        const route = useRoute();
        let isctrl = false;
        const state = reactive({
            params: {
                page: 1,
                pageSize: 12
            },
            isShow: false,
            roomData: [],
            momentStamp: momentStamp,
            patternList: [
                {
                    key: 'deploy_disinfect',
                    value: _t('disinfect.existingMap')
                },
                {
                    key: 'explore_disinfect',
                    value: _t('robot.explore')
                },
                {
                    key: 'disinfect',
                    value: _t('robot.InSitu_disinfect')
                }
            ],
            statusList: [
                {
                    key: 'start',
                    value: _t('system.inProgress')
                },
                {
                    key: 'finish',
                    value: _t('system.normalCompletion')
                },
                {
                    key: 'failed',
                    value: _t('system.abnormalEnd')
                }
            ],
            create_time: '',
            end_time: '',
            operatorList: [],
            formInline: {
                sn: '',
                area_id: '',
                room_id: '',
                disinfect_type: '',
                status: '',
                pageSize: 12,
                page: 1,
                limit: true,
                operator: '',
                start_time: '',
                end_time: ''
            },
            disinfectStatus: '',
            robotList: [],
            totalImg: totalImg(),
            getHM: getHM,
            disinfectType: disinfectType,
            setRobotName: setRobotName,
            total: 0,
            page: 1,
            tableData: [],
            areaName: sessionStorage.areaName,
            roomName: sessionStorage.roomName,
            areaid: sessionStorage.areaid,
            language: getLanguage(),
            filterStatus(val, type) {
                let color, text;
                switch (val) {
                    case 'start':
                        color = '#478ff7';
                        text = _t('disinfect.starting');
                        break;
                    case 'pause':
                        color = '#478ff7';
                        text = _t('disinfect.starting');
                        break;
                    case 'failed':
                        color = '#db503f';
                        text = _t('system.abnormalEnd');
                        break;
                    case 'canceled':
                        color = '#db503f';
                        text = _t('system.abnormalEnd');
                        break;
                    case 'finish':
                        color = '#4fa55d';
                        text = _t('system.normalCompletion');
                        break;
                }
                return type == 1 ? color : text;
            },
            tableRowClassName({ row, rowIndex }) {
                if (row.status == 'start') {
                    return 'start_row';
                }
                return '';
            },
            handleCurrentChange(page) {
                state.formInline.page = page;
                state.getList();
            },
            handleSizeChange(val) {
                state.formInline.pageSize = val;
                state.getList();
            },
            getList() {
                let data = JSON.parse(JSON.stringify(state.formInline));
                console.log(data);
                // if (data.create_time !=''&&data.create_time!=null) {
                //   data.create_time = moment(data.create_time).valueOf()
                // } else {
                //   data.create_time = ''
                // }
                if (state.disinfectStatus == 'failed') {
                    data.status = 'canceled,failed';
                } else {
                    data.status = state.disinfectStatus;
                }
                console.log(state.roomData);
                if (state.roomData.length > 0) {
                    data.room_id = [];
                    state.roomData.map((ele) => {
                        data.room_id.push(ele[1]);
                    });
                    data.room_id = data.room_id.toString();
                }
                let newdata = JSON.parse(JSON.stringify(data));
                newdata.room_id = state.roomData;
                newdata.create_time = state.create_time;
                localStorage.setItem('searchData', JSON.stringify(newdata));
                // data.delete[area_id]
                delete data.area_id;
                taskList(data, true).then((res) => {
                    if (res.code == 200) {
                        state.tableData = res.data.list;
                        state.total = res.data.total;
                        state.page = state.formInline.page;
                    }
                });
            },
            handleSearch() {
                state.page = 1;
                state.formInline.page = 1;
                let time = state.create_time;
                // let room = state.roomData;
                if (time) {
                    state.formInline.start_time = moment(time[0]).valueOf();
                    state.formInline.end_time = moment(time[1]).valueOf() + 1000 * 60 * 60 * 24 - 1 * 1000;
                    // console.log(state.formInline)
                } else {
                    state.formInline.start_time = '';
                    state.formInline.end_time = '';
                }
                // if (room) {
                //   state.formInline.area_id = room[0];
                //   state.formInline.room_id = room[1];
                // } else {
                //   state.formInline.area_id = null;
                //   state.formInline.room_id = null;
                // }
                state.getList();
            },
            resetForm(formName) {
                const form = unref(searchForm);
                form.resetFields();
                state.roomData = '';
                state.disinfectStatus = '';
                state.formInline.room_id = '';
                state.formInline.operator = '';
                state.create_time = '';
                state.formInline.start_time = '';
                state.formInline.end_time = '';
                state.getList();
            },
            getRoomData(val) {
                state.roomData = val;
            },
            disabledDate(time) {
                return time.getTime() > Date.now();
            },
            // 监听键盘
            keyDown() {
                // 键盘按下事件
                document.onkeydown = (e) => {
                    // 取消默认事件
                    e.preventDefault();
                    //事件对象兼容
                    let e1 = e || event || window.event || arguments.callee.caller.arguments[0];
                    //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40  回车：13   ctrl：17   shift：16
                    switch (e1.keyCode) {
                        case 17:
                            isctrl = true; // 如果ctrl按下就让他按下的标识符变为true
                            break;
                    }
                };
                // 键盘抬起事件
                document.onkeyup = (e) => {
                    // 取消默认事件
                    e.preventDefault();
                    //事件对象兼容
                    let e1 = e || event || window.event || arguments.callee.caller.arguments[0];
                    switch (e.keyCode) {
                        case 17:
                            isctrl = false; // 如果ctrl抬起下就让他按下的标识符变为false
                            break;
                    }
                };
            },
            theRobotList() {
                getRobotList({}).then((res) => {
                    if (res.code == 200) {
                        state.robotList = res.data.lists;
                    }
                });
            },
            getTaskTime(row) {
                const a = new Date(row.end_time).getTime();
                const b = new Date(row.create_time).getTime();
                const c = (a - b) / 1000;
                const time = state.getHM(c);
                return time;
            },
            goTaskInfo(row) {
                console.log(row);
                if (!isctrl) {
                    localStorage.setItem('taskInfo', JSON.stringify(row));
                    sessionStorage.sn = row.sn;
                    router.push(`/robot/taskInfo/${row.report}`);
                } else {
                    localStorage.setItem('taskInfo', JSON.stringify(row));
                    sessionStorage.sn = row.sn;
                    window.open(`/#/robot/taskInfo/${row.report}`, '_blank');
                }
            },
            getOpen(value) {
                state.isShow = value;
            },
            getoperator() {
                getAllUser({ userName: '', pageSize: 1000, page: 1 })
                    .then((res) => {
                        // console.log('查询操作人列表',res)
                        state.operatorList = res.data.lists;
                        state.operatorList.unshift({
                            username: 'admin'
                        });
                    })
                    .catch((err) => {
                        state.operatorList = [];
                        state.operatorList.unshift({
                            username: 'admin'
                        });
                    });
            },
            exportExcel() {
                ElMessageBox.confirm(_t('disinfect.exportTip'), _t('disinfect.exportTitle'), {
                    confirmButtonText: _t('system.determine'),
                    cancelButtonText: _t('system.cancel'),
                    type: 'warning'
                }).then(() => {
                    let data = state.formInline;
                    let time = state.create_time;
                    let cretimes = '';
                    let endtimes = '';
                    console.log(time, moment(time).valueOf());
                    if (time) {
                        cretimes = moment(time[0]).valueOf();
                        endtimes = moment(time[1]).valueOf() + 1000 * 60 * 60 * 24;
                    }
                    let room = [];
                    // console.log(state.roomData,room)
                    if (state.roomData.length > 0) {
                        room = [];
                        state.roomData.map((ele) => {
                            room.push(ele[1]);
                        });
                        room = room.toString();
                    } else {
                        room = '';
                    }
                    console.log(state.roomData, room);
                    const params = {
                        language: state.language,
                        create_time: cretimes ? cretimes : undefined,
                        end_time: endtimes ? endtimes - 1000 : undefined,
                        disinfect_type: data.disinfect_type ? data.disinfect_type : undefined,
                        room_id: room ? room : undefined,
                        sn: data.sn ? data.sn : undefined,
                        operator: data.operator ? data.operator : undefined,
                        task_status: state.disinfectStatus == 'failed' ? 'canceled,failed' : state.disinfectStatus ? state.disinfectStatus : undefined,

                        location: Intl.DateTimeFormat().resolvedOptions().timeZone
                    };
                    exportTask(JSON.parse(JSON.stringify(params)))
                        .then((res) => {
                            if (res.code === 200) {
                                window.location.href = res.data.export_url;
                            } else {
                                ElMessage({
                                    type: 'warning',
                                    message: _t('disinfect.exportFail')
                                });
                            }
                        })
                        .catch((err) => {
                            ElMessage({
                                type: 'warning',
                                message: _t('disinfect.exportFail')
                            });
                        });
                });
            }
        });
        onMounted(() => {
            if (route.params.searchData) {
                let searchData = JSON.parse(route.params.searchData);
                state.formInline = searchData;
                state.disinfectStatus = searchData.status;
                state.roomData = Array.from(searchData.room_id);
                state.create_time = searchData.create_time;
            }
            state.getoperator();
            state.theRobotList();
            if (route.params.sn) {
                state.formInline.sn = route.params.sn;
                // state.getList();
            }
            let roomData = route.params.roomData,
                arr = [];
            if (roomData) {
                console.log(roomData);
                roomData.map((item) => {
                    arr.push(item / 1);
                });
                state.roomData.push(arr);
                console.log(state.roomData, '路由获取');
                // state.formInline.area_id = arr[0];
                // state.formInline.room_id = arr[1];
                // state.getList();
            }
            state.handleSearch();
        });
        return {
            ...toRefs(state),
            searchForm,
            moment
        };
    }
};
</script>
<style lang="scss">
.topBox {
    background: #fff;
    position: relative;
    .breadcrumb {
    }
    .exportBtn {
        position: absolute;
        top: 7px;
        right: 14px;
    }
}
.disinfectList {
    .el-form-item__label {
        text-align: left !important;
        padding-right: 0;
    }
    .el-form-item {
        white-space: nowrap !important;
        min-width: 350px;
        width: 22%;
    }
    .createtimeClass {
        min-width: 475px;
    }
    .el-form-item__label {
        // width: 28% !important;
        display: inline-block;
    }
    .el-form-item__content {
        width: 70% !important;
        display: inline-block;
        .el-select,
        .el-cascader {
            width: 90% !important;
            min-width: 140px;
        }
    }
    .el-date-editor .el-range-separator {
        width: 10%;
    }
    tr th {
        background: #fafafa;
    }
    .el-table__row {
        cursor: pointer;
    }
    .start_row td {
        background: #e7f7ff;
    }
}
//
</style>
//
<style lang="scss" scoped>
.disinfectList {
    position: relative;
    h3 {
        margin: 0 0 16px;
    }
    .robotItem {
        display: inline-block;
        position: relative;
        vertical-align: top;
        height: 230px;
        width: 90%;
        padding: 20px 20px 20px 130px !important;
        margin: 0 20px 30px;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        border-radius: 10px;
        font-size: 14px !important;
        cursor: pointer;

        .robotImg {
            position: absolute;
            height: 160px;
            top: 30px;
            left: 40px;
        }
        p {
            img {
                width: 24px;
                margin: -4px 4px 0 0;
            }
            span {
                display: inline-block;
                vertical-align: top;
            }
        }
    }
    .robotTitle {
        font-size: 18px;
        font-weight: bold;
    }
    .searchBtn {
        text-align: right;
        margin-bottom: 20px;
        .openText {
            margin-left: 24px;
            // color: #409eff;
            font-size: 14px;
            cursor: pointer;
        }
    }
    .circular {
        position: relative;
        margin: 2px 6px;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: #000;
    }
}
.noPage {
    text-align: right;
    padding: 46px 24px 28px;
    font-size: 14px;
    color: #606266;
}
</style>
