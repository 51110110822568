<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-10-27 13:47:13
-->
<template>
  <div>
   	<el-cascader :options="options" :placeholder="$t('system.choice')" @change="handleChange" v-model="roomData" :props="propsmultiple"  :collapse-tags="true" clearable />
  </div>
</template>

<script>
import {
  reactive,
  ref,
	toRefs,
	onMounted
} from "vue";
import { regionList, roomList, regionAndRoomList } from '@/apis/region'
export default {
	props: [ "roomData" ],
  setup(props, context) {
    const state = reactive({
			roomData:  props.roomData,
			propsmultiple:{multiple:true},
			options: [],
			getData() {
				regionAndRoomList().then((res => {
					if (res.code == 200) {
						let list = res.data, arr = []
						list.map((item, index) => {
							let obj = {
								label: item.area_name,
								value: item.ID,
								children: []
							}
							arr.push(obj)
							let data = item.room_list
							if (data && data.length > 0) {
								data.map(li => {
									let _obj = {
										label: li.RoomName,
										value: li.ID,
									}
									arr[index].children.push(_obj)
								})
							} else {
								arr[index].disabled = true
							}
						})
						state.options = arr
					}
				}))
			},
			handleChange(value) {
				context.emit("getRoomData", value);
			}
    });
    onMounted(() => {
			state.getData()
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped>

</style>
